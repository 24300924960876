:root {
    --primary-color: #6700B2;
    --secondary-color: #F1F5F9;
    --light-color: #fff;
    --dark-color: #2C004D;
    --text-color: #2C004D;
    --input-text-color: #1C030C;
    --input-border-color: #D1D5DB;
    --input-bg-color: #F9FAFB;
    --footer-bg-color: #181A1E;
}