.app_footer {
    display: flex;
    width: 100%;
    padding: 15px;
    background-color: var(--footer-bg-color);
    align-items: end;

    .footer_text_wrap {
        color: var(--light-color);
        margin: 0 auto;
    }
}