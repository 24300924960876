.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);

    button {
        outline: none;
        cursor: pointer;
        border: 0;
    }

    section {
        width: 90%;
        max-width: 450px;
        margin: 0 auto;
        border-radius: 12px;
        background-color: var(--light-color);
        animation: modal-show 0.3s;
        overflow: hidden;

        header {
            position: relative;
            padding: 18px 64px 16px 16px;
            background-color: var(--primary-color);
            font-weight: 500;
            color: var(--light-color);

            button {
                position: absolute;
                top: 10px;
                right: 15px;
                width: 30px;
                font-size: 21px;
                font-weight: 500;
                text-align: center;
                color: var(--light-color);
                background-color: transparent;
            }
        }

        main {
            padding: 16px;
            border-bottom: 1px solid var(--input-border-color);
            border-top: 1px solid var(--input-border-color);
            font-size: 14px;
        }

        footer {
            padding: 12px 16px;
            text-align: right;

            button {
                padding: 6px 12px;
                border-radius: 5px;
                color: var(--light-color);
                background-color: var(--primary-color);
            }

            button.cancel {
                margin-left: 10px;
                color: var(--dark-color);
                background-color: var(--secondary-color);
            }
        }
    }
}

.modal.open {
    display: flex;
    align-items: center;
    animation: modal-bg-show 0.3s;
}

@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }

    to {
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes modal-bg-show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}