.app_header {
    padding: 20px 0px;
    background-color: var(--light-color);

    .navbar .container-fluid{
        align-items: start;
    }

    .app_logo {
        margin-right: 60px;

        img {
            height: 44px;
        }
    }

    .navbar-expand-lg {
        .navbar-nav {

            .nav-link,
            a {
                color: var(--light-color);
                font-weight: 500;
                font-size: 14px;
                text-decoration: none;
                padding: 10px;
            }
        }
    }

    .header_btn_wrap {
        margin-right: 24px;

        .header_btn {
            img {
                margin-right: 8px;
                border-radius: 100px;
                height: 24px;
                width: 24px;
            }

            select {
                border: none;
                color: var(--primary-color);
                background-color: var(--light-color);
                font-weight: 500;
                font-size: 18px;
            }

            border-color: var(--primary-color);
            border-width: 3px;
            border-radius: 30px;
            padding: 12px 30px;

            span {
                vertical-align: middle;
            }
        }

        .network_type {
            color: var(--primary-color);
            font-weight: 500;
            font-size: 18px;
            cursor: default;
        }
    }

    button {
        &:focus {
            box-shadow: none;
        }
    }

    .dropdown_wrap {
        .header_btn {
            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }

        .dropdown {
            .btn-primary {
                border: 0;
                background: none;
                padding: 0;

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                left: -50px;
                background-color: var(--light-color);
                border-radius: 12px;
                width: 210px;
                box-shadow: 0px 4px 16px -1px rgba(0, 0, 0, .08), 0px 0px 8px -4px rgba(0, 0, 0, .12);
                overflow: hidden;
                padding: 10px 15px;

                .user_profile_wrap {
                    margin-bottom: 15px;

                    .user_profile {
                        text-align: center;

                        img {
                            width: 80px;
                            border-radius: 50%;
                            height: 80px;
                        }
                    }

                    h4 {
                        font-size: 18px;
                        text-align: center;
                        color: var(--text-color);

                        span {
                            cursor: pointer;
                        }
                    }
                }

                .dropdown-item {
                    padding: 6px 8px;
                    color: var(--text-color);

                    &:hover {
                        color: var(--primary-color);
                    }

                    &:active {
                        color: var(--primary-color);
                        background-color: var(--input-bg-color);
                    }

                    svg {
                        font-size: 20px;
                        margin-right: 5px;
                        vertical-align: middle;
                    }
                }
            }
        }

        .disconnect_btn {
            background: var(--primary-color);
            color: var(--light-color);
            border-radius: 30px;
            border-width: 0;
            font-weight: 500;
            font-size: 18px;
            padding: 14px;
        }
    }

    .right_header_wrap {
        display: flex;
    }
}

.center_header_wrap {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 40px;

    ul {
        margin: 0;
        padding: 0;
        display: flex;

        li {
            list-style: none;
            width: 100%;
            text-align: center;
            border-radius: 12px;

            a {
                text-align: center;
                width: 100%;
                padding: 12px;
                text-decoration: none;
                display: inline-block;
                font-weight: 500;
                color: var(--text-color);
                position: relative;
                transition: all 0.5s ease;

                &:hover {
                    background-color: #fff;
                    border-radius: 12px 12px 0 0;
                }

                &.active {
                    &::after {
                        position: absolute;
                        height: 2px;
                        left: 0%;
                        bottom: 0px;
                        content: "";
                        width: 100%;
                        background: var(--primary-color);
                    }
                }

            }
        }
    }
}

@media screen and (max-width:991px) {
    .app_header {
        padding: 0px;
    }

    .app_header .header_btn_wrap .header_btn {
        margin-bottom: 5px;
    }

    .app_header .right_header_wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
    }

    .app_header .center_header_wrap {
        margin-top: 45px;
    }
}
