.deposit_wrap {
    padding: 50px;

    .title {
        text-align: center;
        color: var(--text-color);
        font-weight: 600;
        font-size: 24px;
    }

    .description {
        margin-top: 20px;
        text-align: center;
        color: var(--text-color);
    }

    .bridge {
        margin: 20px auto 0;
        padding: 12px;
        max-width: 340px;
        text-align: center;
        color: var(--text-color);
        border: 2px solid var(--primary-color);
        border-radius: 4px;

        .bridge_type {
            font-size: 18px;
        }
    }

    .deposit_price_wrap {
        margin-top: 22px;
        
        .deposit_price_title {
            display: flex;
            align-items: center;

            h5 {
                margin: 0;
                color: var(--text-color);
                font-weight: 600;
                font-size: 16px;

                svg {
                    font-size: 22px;
                }

                img {
                    margin-right: 5px;
                    height: 22px;
                }
            }
        }

        .deposit_input_wrap {
            margin-top: 15px;
            padding: 14px 19px;
            position: relative;
            background-color: var(--dark-color);
            border-radius: 12px;

            .form-control {
                font-size: 20px;
                border-radius: 8px 0px 0px 8px;
                border-left: 1px solid var(--input-border-color);
                border-top: 1px solid var(--input-border-color);
                border-bottom: 1px solid var(--input-border-color);
                height: 62px;
                min-width: 100px;
                background-color: var(--input-bg-color);
                color: var(--text-color);
                font-weight: 600;
                width: 100%;
                padding: 10px 10px 10px 50px;

                &:focus {
                    box-shadow: none;
                }
            } 

            .input_icn {
                position: absolute;
                left: 40px;
                top: 68px;
                transform: translate(-50%, -53%);
                font-size: 24px;

                img {
                    height: 24px;
                }
            }

            .input_title {
                color: var(--light-color);
            }

            .deposit_inner_input {
                display: flex;
                align-items: center;

                .select_wrap {
                    color: var(--text-color);
                    border: 1px solid var(--input-border-color);
                    padding: 10px;
                    border-radius: 0px 8px 8px 0px;
                    width: 100px;
                    height: 62px;
                    background: url("../images/down.svg") var(--light-color) no-repeat center right 10px;
                    background-size: 10px;

                    // background-position: center right 10px;
                    &:focus {
                        box-shadow: none;
                    }
                }

            }
        }

        .wallet_bal {
            margin-top: 9px;
            color: var(--light-color);
        }
    }

    .arrows {
        margin: 10px 0;
        text-align: center;

        img {
            padding: 7px;
            background-color: var(--secondary-color);
            border-radius: 50%;
        }

        img:hover {
            background-color: var(--light-color);
        }
    }

    .deposit_details_wrap {
        .deposit_details {
            display: flex;
            align-items: center;

            h5 {
                margin-bottom: 0px;
                font-size: 16px;
                color: var(--text-color);
                border-radius: 25px;

                svg {
                    font-size: 22px;
                }

                img {
                    height: 22px;
                }
            }
        }

        .deposit_inner_details {
            background-color: var(--dark-color);
            padding: 20px;
            border-radius: 12px;
            margin-top: 10px;
            display: flex;
            align-items: center;

            p {
                margin: 0;
                padding: 3px 0 0 20px;
                color: var(--light-color);
             }

            .input_icn {
                font-size: 24px;

                img {
                    height: 24px;
                }
            }
        }
    }

    .deposit_btn_wrap {
        display: flex;    
        justify-content: center;
        margin-top: 50px;

        .deposit_btn {
            display: flex;    
            align-items: center;
            justify-content: center;
            background-color: var(--primary-color);
            border-radius: 30px;
            color: var(--light-color);
            font-size: 18px;
            font-weight: 600;
            width: 70%;
            padding: 16px;

            img {
                height: 18px;
            }

            svg {
                font-size: 18px;
            }

            &:hover {
                opacity: 0.9;
            }

            &:focus {
                border-color: var(--primary-color);
                opacity: 0.9;
                background-color: var(--primary-color);
                color: var(--light-color);
            }

            .loader_message {
                padding-left: 10px;
            }
        }
    }

    .transaction_hash {

        a {
            font-size: 10px;
            text-align: center;
            word-wrap: break-word;
            background: var(--secondary-color);
            padding: 10px 0px;
            border-radius: 10px;
            display: block;
            margin-top: 5px;
            text-decoration: none;

            &:first-child {
                margin-top: 15px;
            }

            svg {
                font-size: 18px;
            }
        }
    }

    .token_info {
        color: var(--light-color);
        font-size: 12px;
    }

    .btn_switch {
        color: var(--dark-color);
        background-color: var(--light-color);
        border-color: var(--dark-color);
        padding: 0 6px;
        font-size: 12px;
        font-weight: 600;

        &:hover {
            color: var(--light-color);
            background-color: var(--dark-color);
        }
    }
}

@media screen and (max-width:400px) {
    .deposit_wrap .deposit_price_wrap .deposit_input_wrap .deposit_inner_input .select_wrap{
        padding: 15px 30px 15px 10px;
    }
}