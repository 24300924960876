@import "./withdrawAccount.scss";

.account_wrap {
    padding: 50px;

    .account_title {
        margin-bottom: 30px;
        color: var(--test-color);

        h6 {
            margin: 0;

            svg {
                cursor: pointer;
            }

            span {
                word-wrap: break-word;
            }
        }
    }

    .account_tabs {
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            gap: 0px;
            margin-bottom: 30px;

            li {
                list-style: none;
                width: 100%;
                text-align: center;

                a {
                    padding: 12px;
                    display: block;
                    text-decoration: none;
                    position: relative;
                    font-weight: 500;
                    font-size: 14px;
                    color: var(--text-color);
                    position: relative;
                    transition: all 0.5s ease;
                    border-radius: 10px;

                    &:hover {
                        border-radius: 12px 12px 0 0;
                        background-color: var(--secondary-color);
                    }

                    &.active {
                        color: var(--primary-color);

                        &::after {
                            position: absolute;
                            height: 3px;
                            left: 50%;
                            bottom: 0px;
                            content: "";
                            width: 100%;
                            background: var(--primary-color);
                            transform: translate(-50%);
                        }
                    }
                }
            }
        }
    }
}